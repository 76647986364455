<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path
      fill="currentColor"
      d="M375.385-860v-40h209.23v40h-209.23ZM460-415.385h40v-209.23h-40v209.23ZM480-120q-66.308 0-124.5-25.038-58.192-25.039-101.808-68.654-43.615-43.616-68.654-101.808Q160-373.692 160-440q0-66.308 25.038-124.5 25.039-58.192 68.654-101.808 43.616-43.615 101.808-68.654Q413.692-760 480-760q58.154 0 112.462 20.769 54.307 20.769 99.692 58.769l43.692-43.692 28.308 28.308-43.692 43.692q38 45.385 58.769 99.692Q800-498.154 800-440q0 66.308-25.038 124.5-25.039 58.192-68.654 101.808-43.616 43.615-101.808 68.654Q546.308-120 480-120Zm0-40q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z"
    />
  </svg>
</template>
